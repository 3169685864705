:root {
  --black: #022b3a;
  --light-black: rgba(2, 43, 58, 0.4);
  --accent: #6a67ce;
  --light-accent: rgba(106, 103, 206, 0.6);
  --secondary: #3be8b0;
  --red: #fc636b;
  --white: #ffffff;
  --light-grey: rgba(219, 219, 219, 0.6);
}

body {
  margin: 0;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Domine', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 18px;
  }
}

.marker-fix-20 {
  top: -20px !important;
}

.marker-fix-10 {
  top: -10px !important;
}

video {
  object-fit: cover !important;
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}
